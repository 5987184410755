import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './TopNav.css'

const TopNav = ({openMenu, toggleMenu}) => {
  const user = localStorage.getItem('data')
  const [data, setData] = useState(JSON.parse(user))
  const [mobile, setMobile] = useState(false)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaChange = (event) => {
      setMobile(event.matches); // Set to true if matches, false otherwise
    };
    handleMediaChange(mediaQuery); // Set initial value
    mediaQuery.addEventListener('change', handleMediaChange); // Listen for changes
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange); // Clean up listener
    };
  }, []);
  return (
    // <>
    //   {
    //     data && 
    //   }
    // </>
    <section className='topNav'>
          <header>
          {
            mobile && <img alt='profile' src='/images/menu.png' onClick={()=>{
              toggleMenu(!openMenu)
            }} />
          }
          </header>
            <Link to='/profile' style={{textDecoration: 'none', color:'inherit'}}>
          <div>
              <img alt='profile' src='/images/profile.png' />
              <h2>
                Shezzy
              </h2>
          </div>
          </Link>
        </section>
  )
}

export default TopNav