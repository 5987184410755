import React, { useMemo, useEffect, useState } from 'react'
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel} from '@tanstack/react-table'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';
import './Table.css'

const Tables = ({users, type}) => {
  const navigate = useNavigate()
  // const allUsers = users
  const [searchQuery, setSearchQuery] = useState(''); 
  const [allUsers, setAllUsers] = useState([])
  const freeUsers = users.filter(item => item.pro === false)
  // const proUsers = users.filter(item => item.pro === true)
  const proUsersData = users.filter(item => item.pro === true)
  const proUsers = [...proUsersData.slice(0,3), proUsersData[5]]
  // const pUsers = users.filter(item => item.walletLimit === 100)
  const pUsers = [...proUsersData.slice(1,3)]
  const vUsers = users.filter(item => item.walletLimit === 400)
  const oUsers = users.filter(item => item.walletLimit === 400)
  const zUsers = users.filter(item => item.walletLimit === 600)
  useEffect(()=>{
    if(type === 'all'){
      setAllUsers(users)
    }else if(type === 'free'){
      setAllUsers(freeUsers)
    }else if(type === 'pro'){
      setAllUsers(proUsers)
    }else if(type === 'phoenix'){
      setAllUsers(pUsers)
    }else if(type === 'val'){
      setAllUsers(vUsers)
    }else if(type === 'odin'){
      setAllUsers(oUsers)
    }else if(type === 'zeus'){
      setAllUsers(zUsers)
    }
  }, [users, type])

  const data = useMemo(() => {
    if (!searchQuery) return allUsers;
    return allUsers.filter(user =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.chat_id.toString().includes(searchQuery) ||  
    user.walletLimit.toString().includes(searchQuery) 
    );
  }, [searchQuery, allUsers]); // Use wallets or an empty array if wallets is undefined
  const columns = [
   {
     header : 'Chat ID',
     accessorKey : 'chat_id',
     footer: 'Chat ID'
   },
   {
     header : 'Username',
     accessorKey : 'username',
     footer: 'Username'
   },
   {
     header : 'Wallet Limit',
     accessorKey : 'walletLimit',
     footer: 'Wallet Limit'
   },
   {
     header : 'Pro',
     accessorKey : 'pro',
     footer: 'Pro'
   },
   {
     header : 'Transfer Key',
     accessorKey : 'transferKey',
     footer: 'Transfer Key'
   },
   {
     header : 'Expiry',
     accessorKey : 'countdownEndTime',
     footer: 'Expiry',
     cell: info => 
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED)
   },
   {
     header : 'Tt wallets',
     accessorKey : 'wallets.length',
     footer: 'Tt wallets'
   },
   {
     header : 'Daily Limit',
     accessorKey : 'dailyLimit' || 0,
     footer: 'Daily Limit'
   },
  ]
  const table = useReactTable({data, columns, getCoreRowModel: getCoreRowModel(), getPaginationRowModel: getPaginationRowModel()})
  useEffect(() => {
    table.setPageSize(50); // Set page size to 100
  }, [table]);
  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(allUsers);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'wallets.xlsx');
  };
  return (
    <div className='w3-container' style={{marginBottom: '9rem'}}>
    {allUsers.length > 0 ? <>
    <main style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '4rem',
      marginBottom: '1rem'
    }}>
      <p style={{fontSize: '25px', fontWeight: '900'}}>TOTAL : {allUsers.length}</p>
      <button style={{padding: '5px 10px', backgroundColor: 'rgb(93, 219, 223)', color: 'white', border: 'none'}} onClick={handleDownload}>Download PDF</button>
    </main>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search by address, name, or group"
        style={{ marginBottom: '1rem', padding: '0.5rem', width: '100%', outline: 'none' }}
      />
      <table className='w3-table-all'>
      <thead>
      {table.getHeaderGroups().map(headerGroup=>(
        <tr key={headerGroup.chat_id}>
          {headerGroup.headers.map(header=>(
            <th key={header.chat_id}>
              {flexRender(
                header.column.columnDef.header,
                header.getContext()
              )}
            </th>
          ))}
        </tr>
      ))}
      </thead>
        <tbody style={{cursor: 'pointer'}}>
        {table.getRowModel().rows.map(row=>(
          <tr onClick={()=>{
            navigate('/allusers/user', { state: { user: row.original } })
            console.log(row.original.chat_id)
          }} key={row.chat_id}>
            {row.getVisibleCells().map(cell=>(
              <td key={cell.chat_id}>
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={()=>table.setPageIndex(0)}>First page</button>
        <button disabled={!table.getCanPreviousPage()} onClick={()=>table.previousPage()}>Prev page</button>
        <button disabled={!table.getCanNextPage()} onClick={()=>table.nextPage()}>Next page</button>
        <button onClick={()=>table.setPageIndex(table.getPageCount() - 1)}>Last page</button>
      </div>
     </> : <div style={{textAlign: 'center'}}><h3>No data</h3></div>
    }
    </div>
  )
}

export default Tables
