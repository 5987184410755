import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import './walletuser.css'
import Loading from '../Loading/Loading'

const Walletuser = ({users}) => {
  const [submitted, setSubmitted] = useState(false)
  const [text, setText] = useState('')
  const freeUsers = users.filter(item => item.pro === false)
  const proUsers = users.filter(item => item.pro === true)
  const pUsers = users.filter(item => item.walletLimit === 100)
  const vUsers = users.filter(item => item.walletLimit === 200)
  const oUsers = users.filter(item => item.walletLimit === 400)
  const zUsers = users.filter(item => item.walletLimit === 600)
  const [multiple, setMultiple] = useState(true)
  const [formData, setFormData] = useState({
    category : 'Choose a category',
    message : ''
  })
  const [singleformData, setSingleformData] = useState({
    chatID : '',
    message : ''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};
  const handleSingleChange = (e) => {
    const { name, value } = e.target;
    setSingleformData({
        ...singleformData,
        [name]: value
    });
};
function parseAddressesAndNames(input) {
  try{
    const lines = input.split("\n");
    const eachLines = lines.filter(item => item.trim() !== "")
    const result = eachLines.map((line) => {
      const addressData = line.split(" ")
      const addressParts = addressData.filter(parts => parts.trim() !== "")
      const [address, name, ...groupn] = addressParts;
      const groupdata = groupn.join(" ");
      const group = groupdata.toUpperCase()
  
      // Define default values
      const defaultAddress = "default_address";
      const defaultName = "default_name";
      const defaultGroup = "default_group";
  
      if (!address || !name || !group) {
        return { address: defaultAddress, name: defaultName, group: defaultGroup };
      }
  
      if (group === "A" || group === "B" || group === "D" || group === "G") {
        return { address, name, group };
      } else {
        console.log('Invalid arrangement')
        return { address: defaultAddress, name: defaultName, group: defaultGroup };
      }
    });
    return result;    
  }catch(e){
    console.log('error in adding wallets')
  }
}
  function sendMessageToAllUsers(e){
    e.preventDefault()
    setSubmitted(true)
    setText('')
    const walletsData = parseAddressesAndNames(formData.message)
    console.log(walletsData)
    const userGroup = formData.category === 'all' ? users : formData.category === 'Pro' ? proUsers : formData.category === 'Phoenix' ? pUsers : formData.category === 'Valkyrie' ? vUsers : formData.category === 'Odin' ? oUsers : formData.category === 'Zeus' ? zUsers : freeUsers
    userGroup.forEach(user => {
        walletsData.forEach(walletData =>{
          const wallet = {
            address: walletData.address,
            name: walletData.name,
            group: walletData.group
          }
             axios.post(
              `https://godeyedb-6fcfc52eb9b0.herokuapp.com/users/${user.chat_id}/addWallet`,
              {
                wallet: wallet,
              },
            )
            .then(res=>{
              console.log("Wallet added");
              setSubmitted(false)
              setText('Wallet have been uploaded successfully')
            })
            .catch(e=>{
              console.log('not added', e)
              setSubmitted(false)
              setText('Wallet failed to upload')
            })
        })
    });
  }
  function sendMessageToUsers(e){
    e.preventDefault()
    setSubmitted(true)
    setText('')
    console.log('nice')
    const walletsData = parseAddressesAndNames(singleformData.message)
    console.log(walletsData)
      walletsData.forEach(walletData=>{
        const wallet = {
          address: walletData.address,
            name: walletData.name,
            group: walletData.group
        }
        console.log(wallet)
          axios.post(
           `https://godeyedb-6fcfc52eb9b0.herokuapp.com/users/${singleformData.chatID}/addWallet`,
           {
             wallet: wallet,
           },
         )
         .then(res=>{
           console.log("Wallet added");
           setSubmitted(false)
              setText('Wallet have been uploaded successfully')
         })
         .catch(e=>{
           console.log('not added', e)
           setSubmitted(false)
              setText('Wallet failed to upload')
         })
      })
  }
  return (
    <div className='walletuser'>
    {submitted && <span className='submitting'><Loading/></span>}
    <h1>Send Wallet</h1>
       <ul>
      <li style={{backgroundColor: multiple ? '#04283a': 'white', color: multiple ? 'white': 'black'}} onClick={()=>{
        setMultiple(true)
      }}>Send to multiple users</li>
      <li style={{backgroundColor: multiple ? 'white': '#04283a', color: multiple ? 'black': 'white'}} onClick={()=>{
        setMultiple(false)
      }}>Send to single user</li>
    </ul>
      <section style={{opacity: submitted ? '0.2' : '1'}}>
      {
        multiple ? <form onSubmit={sendMessageToAllUsers}>
        <div>
        <label htmlFor='category'>Category</label>
        <select
          name="category"
          value={formData.category}
          onChange={handleChange}
          required={true}
        >
          <option value='Select category' disabled>Select category</option>
          <option value='all'>All users</option>
          <option value='Pro'>Pro users</option>
          <option value='Phoenix'>Phoenix users</option>
          <option value='Valkyrie'>Valkyrie users</option>
          <option value='Odin'>Odin users</option>
          <option value='Zeus'>Zeus users</option>
          <option value='free'>Free users</option>
        </select>
        </div>
        <div>
        <label htmlFor="message">Wallets</label>
    <textarea
        name="message"
      value={formData.message}
      onChange={handleChange}
      required={true}
    />
        </div>
        <button type='submit'>Add wallet</button>
       {text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>
          {text}
        </span>
       } 
      </form> :  
      
      //single users 
       <form onSubmit={sendMessageToUsers}>
        <div>
        <label htmlFor='chatID'>Chat ID</label>
       <input
        name='chatID'
        value={singleformData.chatID}
        onChange={handleSingleChange}
        type='number'
        required={true}
       />
        </div>
        <div>
        <label htmlFor="message">Wallets</label>
    <textarea
        name="message"
      value={singleformData.message}
        onChange={handleSingleChange}
        required={true}
    />
        </div>
        <button type='submit'>Add wallet</button>
        {text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>
          {text}
        </span>
       } 
      </form>
      }
    
      </section>
    </div>
  )
}

export default Walletuser
