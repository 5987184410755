import React, { useEffect, useState} from 'react'
import './Dashboard.css'
import NavBar from '../../components/NavBar/NavBar'
import { Outlet, Navigate } from 'react-router-dom'
import TopNav from '../../components/TopNav/TopNav'
import { getAllUsers } from '../../api/api'
import Loading from '../../components/Loading/Loading'

const Dashboard = ({users}) => {
    const [openMenu, setOpenMenu] = useState(true)
    function toggleMenu(cond){
        setOpenMenu(cond)
    }
    const isLogged = localStorage.getItem('logged')
    // const isLogged = true

    // useEffect(()=>{
    //     if(isLogged){
    //         setTimeout(()=>{
    //             localStorage.removeItem('logged')
    //         },3* 60 * 60 * 1000)
    //     }
    // })
    

    return (
        <>
            {
                isLogged && <>{
                    users && <>
                    <div className='dashboard'>
                    <div className='dNav'>
                        <NavBar openMenu={openMenu} toggleMenu={toggleMenu} />
                    </div>
                    <div className='body'>
                        <TopNav openMenu={openMenu} toggleMenu={toggleMenu} />
                        {
                            users.length > 0 ? 
                        <Outlet context={users} />: <Loading/>
                    }
                    </div> 
                </div>
                    </>
                }</>
            }
            {
                !isLogged && <Navigate to='/login' />
            }
        </>


    )
}

export default Dashboard

