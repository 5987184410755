import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import '../SignUp/SignUp.css'
import { Navigate } from 'react-router-dom'


const Login = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    const [loginData, setLoginData] = useState({})
    const [text, setText] = useState('')

    function handleChange(e){
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const body = {
        username: formData.username,
        password: formData.password
    }
    function handleSubmit (e){
        e.preventDefault()
        console.log(body)
        console.log(process.env.REACT_APP_EMAIL)
        if(formData.username === process.env.REACT_APP_EMAIL && formData.password === process.env.REACT_APP_PWD){
            setLoggedIn(true)
            localStorage.setItem('logged', loggedIn)
            console.log('nice')
        }else{
            setText('Incorrect credentials')
        }
    }

    const logged = localStorage.getItem('logged')
    // const logged = true

useEffect(()=>{
    if(logged){
        setLoggedIn(true)
    }else{
        setLoggedIn(false)
    }
},[logged])


  return (
    <>
    {
        !logged &&  <div className='signUp'>
        <h1>
            Log in
        </h1>
        <form onSubmit={handleSubmit}>
            <div>
            <label>
                Fullname :  
                <input
                 type='text'
                 name='username'
                 onChange={handleChange}
                 value={formData.username}
                 />
            </label>
            </div>
            <div>
            <label>
                Passowrd :  
                <input
                 type='text'
                 name='password'
                 onChange={handleChange}
                 value={formData.password}
                 />
            </label>
            <h3   style={{color:'red'}}>{text}</h3>
            </div>
            <span style={{color:'red', fontFamily: 'Monts boldl'}}>{error}</span>
            <button type='submit'>Login</button>
        </form>
    </div>
    }
    {
        logged && <Navigate to='/' />
    }
   </>
  )
}

export default Login