import React, { useState } from 'react'
import axios from 'axios'
import './Messages.css'
import Loading from '../../components/Loading/Loading'

const Messages = ({users}) => {
  const [submitted, setSubmitted] = useState(false)
  const [text, setText] = useState('')
  const [multiple, setMultiple] = useState(true)
  const [formData, setFormData] = useState({
    category : 'Choose a category',
    message : ''
  })
  const [singleformData, setSingleformData] = useState({
    chatID : '',
    message : ''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};
  const handleSingleChange = (e) => {
    const { name, value } = e.target;
    setSingleformData({
        ...singleformData,
        [name]: value
    });
};
  function sendMessageToAllUsers(e){
    e.preventDefault()
    setSubmitted(true)
    setText('')
    axios.post('https://godeye2-52cb709df6b0.herokuapp.com/broadcast', {
      category: formData.category,
      message: formData.message.includes("https://") ? `[Tweet from Godeye](${formData.message})` : formData.message
    })
    .then(res=>{
      console.log(res)
      setSubmitted(false)
      setText('Message have been sent successfully')
    })
    .catch(e=>{
      console.log(e)
      setSubmitted(false)
      setText('Message failed to send')
    })
  }
console.log(singleformData)
  function sendMessageToUsers(e){
    e.preventDefault()
    setSubmitted(true)
    setText('')
    axios.post('https://godeye2-52cb709df6b0.herokuapp.com/message', {
          chatID: singleformData.chatID,
          message: singleformData.message.includes("https://") ? `[Tweet from Godeye](${singleformData.message})` : singleformData.message
        })
    .then(res=>{
      console.log(res)
      setSubmitted(false)
      setText('Message have been sent successfully')
    })
    .catch(e=>{
      console.log(e)
      setSubmitted(false)
      setText('Message failed to send')
    })
  }
  return (
    <div className='messages'>
    {submitted && <span className='submitting'><Loading/></span>}
    <ul>
      <li style={{backgroundColor: multiple ? '#04283a': 'white', color: multiple ? 'white': 'black'}} onClick={()=>{
        setMultiple(true)
      }}>Send to multiple users</li>
      <li style={{backgroundColor: multiple ? 'white': '#04283a', color: multiple ? 'black': 'white'}} onClick={()=>{
        setMultiple(false)
      }}>Send to single user</li>
    </ul>
      <section style={{opacity: submitted ? '0.2' : '1'}}>
      {
        multiple ? <form onSubmit={sendMessageToAllUsers}>
        <div>
        <label htmlFor='category'>Category</label>
        <select
          name="category"
          value={formData.category}
          onChange={handleChange}
          required={true}
        >
          <option value='Select category' disabled>Select category</option>
          <option value='all'>All users</option>
          <option value='Pro'>Pro users</option>
          <option value='Phoenix'>Phoenix users</option>
          <option value='Valkyrie'>Valkyrie users</option>
          <option value='Odin'>Odin users</option>
          <option value='Zeus'>Zeus users</option>
          <option value='free'>Free users</option>
        </select>
        </div>
        <div>
        <label htmlFor="message">Message</label>
    <textarea
        name="message"
      value={formData.message}
          required={true}
          onChange={handleChange}
    />
        </div>
        <button type='submit'>Send message</button>
        {text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>
          {text}
        </span>
       } 
      </form> :  //single users 
       <form onSubmit={sendMessageToUsers}>
        <div>
        <label htmlFor='chatID'>Chat ID</label>
       <input
        name='chatID'
        value={singleformData.chatID}
        onChange={handleSingleChange}
          required={true}
        type='number'
       />
        </div>
        <div>
        <label htmlFor="message">Message</label>
    <textarea
        name="message"
      value={singleformData.message}
          required={true}
          onChange={handleSingleChange}
    />
        </div>
        <button type='submit'>Send message</button>
        {text && <span style={{
          fontSize: '12px',
          backgroundColor: '#d6f3e8',
          color: '#428a6f',
          padding: '15px',
          textAlign: 'center'
        }}>
          {text}
        </span>
       } 
      </form>
      }
    
      </section>
    </div>
  )
}

export default Messages
