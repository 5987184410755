import React, { useEffect } from 'react'
import { useState } from 'react'
import './NavBar.css'
import { NavLink} from 'react-router-dom'

const NavBar = ({openMenu, toggleMenu}) => {
    const user = localStorage.getItem('data')
    // const [data, setData] = useState(JSON.parse(user))
    const [data, setData] = useState({
        access: 'admin'
    })
    const [admin, setAdmin] = useState(true)

    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      // Function to check screen width
      const checkMobileView = () => {
        setIsMobile(window.innerWidth <= 620);
      };
  
      // Check on mount
      checkMobileView();
  
      // Add resize listener
      window.addEventListener('resize', checkMobileView);
  
      // Cleanup on unmount
      return () => window.removeEventListener('resize', checkMobileView);
    }, []);
  return (
    <>
    {
        admin && <div className='navbar'    style={{
        transform: isMobile && openMenu ? 'translateX(-100%)' : 'translateX(0%)',
      }}>
        <img alt='cancel' src='/images/cancelg.png' className='can' onClick={()=>{
                setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} /> <img alt='logo' src='/images/logo2.png' className='logo' />
        <nav>
            <ul>
            <NavLink to='/' activeclassname = 'active' onClick={()=>{
                setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }}  ><li>Dashboard</li></NavLink>
            </ul>
            <p>Announcements</p>
            <ul>
            <NavLink to='/send-message' activeclassname = 'active' onClick={()=>{
            setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Send Message</li></NavLink>
            </ul>
            <p>Users</p>
            <ul>
            <NavLink to='/allusers' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>All users</li></NavLink>
            <NavLink to='/prousers' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Pro users</li></NavLink>
            <NavLink to='/freeusers' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Free users</li></NavLink>
            <NavLink to='/zeus' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Zeus users</li></NavLink>
            <NavLink to='/odin' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Odin users</li></NavLink>
            <NavLink to='/valkyrie' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Valkyrie users</li></NavLink>
            <NavLink to='/phoenix' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Phoenix users</li></NavLink>
            <NavLink to='/videos' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Add user</li></NavLink>
            </ul>
            <p>Wallets</p>
            <ul>
                <NavLink to='/allwallets' activeclassname = 'active' onClick={()=>{
                      setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
                }} ><li>View all wallets</li></NavLink>
                <NavLink to='/send_wallets' activeclassname = 'active' onClick={()=>{
                 setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
                }} ><li>Users/Wallets</li></NavLink>
            </ul>
            <p>Revenue</p>
            <ul>
                <NavLink to='/mentorship' activeclassname = 'active' onClick={()=>{
                    setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
                }} ><li>Total revenue</li></NavLink>
                <NavLink to='/newsletter' activeclassname = 'active' onClick={()=>{
                    setTimeout(()=>{
                        toggleMenu(false)
                    }, 500)
                }} ><li>Update revenue</li></NavLink>
            </ul>
            <p>Logout</p>
            <ul>
                <NavLink to='/login'>
                <li onClick={()=>{
                    localStorage.removeItem('logged')
                }}>Logout</li>
                </NavLink>
            </ul>
        </nav>
    </div>
    }
    {
        !admin && <div className='navbar'>
        <img alt='logo' src='/images/logo.png' className='logo' />
        <nav>
            <p>Blogs</p>
            <ul>
            <NavLink to='/create-blog' activeclassname = 'active' onClick={()=>{
               setTimeout(()=>{
                    toggleMenu(!openMenu)
                }, 200)
            }} > <li>Create Blog</li></NavLink>
                <NavLink to='/blogs' activeclassname = 'active' onClick={()=>{
                    setTimeout(()=>{
                        toggleMenu(false)
                    }, 500)
                }} ><li>View Blogs</li></NavLink>
            </ul>
            <p>Logout</p>
            <ul>
                <NavLink to='/login'>
                <li onClick={()=>{
                    localStorage.removeItem('logged')
                }}>Logout</li>
                </NavLink>
            </ul>
        </nav>
    </div>
    }
    </>
    
  )
}

export default NavBar