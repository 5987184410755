import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './EachUser.css'
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel} from '@tanstack/react-table'
import * as XLSX from 'xlsx';
import axios from 'axios';
 
const EachUser = () => {
    const location = useLocation();
    const data = location.state
    const eachUser = data.user
    const wallets = eachUser.wallets
    const [searchQuery, setSearchQuery] = useState(''); 
    const [editwalletLimit, setEditwalletLimit] = useState(false)
    const [editreferralCount, setEditReferralCount] = useState(false)
    const [editcountdownEndTime, setEditCountdownEndTime] = useState(false)
    const [walletLimit, setWalletLimit] = useState(eachUser.walletLimit)
    const [referralCount, setReferralCount] = useState()
    const [countdownEndTime, setCountdownEndTime] = useState(eachUser.countdownEndTime)
    const [deletes, setDeletes] = useState(false)
    const [alert, setAlert] = useState(false)
    function handleLimit(e){
        setWalletLimit(e.target.value)
    }
    function handleRefCount(e){
        setReferralCount(e.target.value)
    }
    function handleTime(e){
        setCountdownEndTime(e.target.value)
    }
    
function convertDate(isoDate) {
    const date = new Date(isoDate);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    const month = monthNames[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    if(month && day && year){
      const formattedDate = `${month} ${day}, ${year} `;
      return formattedDate;
    }else{
      return 'Free'
    }
  }

  const data2 = useMemo(() => {
    if (!searchQuery) return wallets;
    return wallets.filter(wallet =>
      wallet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      wallet.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      wallet.group.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, wallets]); // Use wallets or an empty array if wallets is undefined
  const columns = useMemo(
    () => [
      {
        header: 'Address',
        accessorKey: 'address',
        footer: 'Address',
      },
      {
        header: 'Name',
        accessorKey: 'name',
        footer: 'Name',
      },
      {
        header: 'Group',
        accessorKey: 'group',
        footer: 'Group',
      },
    ],
    []
  );
  
  const table = useReactTable({
    data: data2, // Pass data2 instead of just data2
    columns, // No issues here, but ensure it's memoized
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  useEffect(()=>{
    table.setPageSize(50)
  }, [table])

const apiUrl = process.env.REACT_APP_API_URL

  function handleDelete(){
    try{
        const res = axios.delete(`${apiUrl}${eachUser.chat_id}`)
        console.log(res.data)
        setDeletes(false)
    }catch(e){
        console.log('error')
    }
  }
  function changeLimit(){
        axios.post(`${apiUrl}${eachUser.chat_id}/setWalletLimit`, {walletLimit : walletLimit})
        .then(res=>{
            console.log(res)
        })
        .catch(e=>{
            console.log(e)
        })
  }
  function changeDate(){
    console.log('date')
  }
//   function changeRef(){
//     console.log('ref')
//   }
  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(wallets);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'wallets.xlsx');
  };
  return (
    <div className='eachUser'>
    {
        alert && <p className='copied'>Copied</p>
    }
    <section>
    <div>
        <h1>Chat ID - </h1>
        <p>{eachUser.chat_id}</p>
     </div>
     <div>
        <h1>Username - </h1>
        <p>{eachUser.username}</p>
     </div>
     <div>
        <h1>Wallet Limit - </h1>
        {
            editwalletLimit ? <input
                name='walletLimit'
                value={walletLimit}
                onChange={handleLimit}
            /> :  <p>{walletLimit}</p>
        }
        <span>
            <img onClick={()=>{
                setEditwalletLimit(true)
            }} alt='edit' src='/images/edit.png'/>
            <img onClick={()=>{
                setEditwalletLimit(false)
                changeLimit()
            }} alt='checkmark' src='/images/check.png'/>
        </span>
     </div>
     <div>
        <h1>Transfer Key - </h1>
        <p>{eachUser.transferKey ? eachUser.transferKey : 'no transfer'}</p>
     </div>
     <div>
        <h1>Referral Code - </h1>
        <p>{eachUser.referral ? eachUser.referral.referralCode : 'no code yet'}</p>
     </div>
     <div>
        <h1>Referral Count - </h1>
        {
            editreferralCount ? <input
                name='referralCount'
                value={referralCount}
                onChange={handleRefCount}
            />  : <p>{eachUser.referral ? eachUser.referral.referralCount : 'no count'}</p>
        }
        
        {/* <span>
            <img onClick={()=>{
                setEditReferralCount(true)
            }} alt='edit' src='/images/edit.png'/>
            <img 
            onClick={()=>{
                setEditReferralCount(false)
                changeRef()
            }} 
            alt='checkmark' src='/images/check.png'/>
        </span> */}
     </div>
     <div>
        <h1>Pro plan - </h1>
        <p>{walletLimit === 10 ? 'Free' : eachUser.walletLimit === 100 ? 'Phoenix' : eachUser.walletLimit === 200 ? 'Valkyrie' : eachUser.walletLimit === 400 ? 'Odin' : eachUser.walletLimit === 600 ? 'Zeus' : 'Free'}</p>
     </div>
     <div>
        <h1>Wallet address - </h1>
        <p>{eachUser.key ? eachUser.key.publicKey : 'no address'}</p>
     </div>
     <div>
        <h1>Expiry date - </h1>
        {
            editcountdownEndTime ? <input
                name='Expiry'
                value={countdownEndTime}
                onChange={handleTime}
            /> : <p>{eachUser.countdownEndTime ? convertDate(eachUser.countdownEndTime) : ''}</p>
        }
        <span>
            <img onClick={()=>{
                setEditCountdownEndTime(true)
            }} alt='edit' src='/images/edit.png'/>
            <img onClick={()=>{
                setEditCountdownEndTime(false)
                changeDate()
            }} alt='checkmark' src='/images/check.png'/>
        </span>
     </div>
     <div>
        <button onClick={()=>{
            setDeletes(true)
        }} className='button'>Delete user</button>
       
        {
            deletes && <> <p>Are you sure ?</p><span>
            <button onClick={handleDelete} className='button'>Yes</button>
            <button onClick={()=>{
                setDeletes(false)
            }} className='button'>No</button>
        </span>
        </>
        }
     </div>
    </section>
    
    <main className='w3-container' style={{marginBottom: '9rem'}}>
    <main style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '4rem',
      marginBottom: '1rem'
    }}>
      <p style={{fontSize: '25px', fontWeight: '900'}}>TOTAL : {wallets.length}</p>
      <button onClick={handleDownload} style={{padding: '5px 10px', backgroundColor: 'rgb(93, 219, 223)', color: 'white', border: 'none', cursor: 'pointer'}}>Download PDF</button>
    </main>
    <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search by address, name, or group"
        style={{ marginBottom: '1rem', padding: '0.5rem', width: '100%', outline: 'none' }}
      />
    <table className='w3-table-all'>
  <thead>
    {table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}> {/* Use headerGroup.id instead */}
        {headerGroup.headers.map(header => (
          <th key={header.id}> {/* Use header.id instead */}
            {flexRender(
              header.column.columnDef.header,
              header.getContext()
            )}
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody style={{ cursor: 'pointer' }}>
    {table.getRowModel().rows.map(row => (
      <tr onClick={()=>{
        navigator.clipboard.writeText(row.original.address)
        setAlert(true)
        setTimeout(()=>{
            setAlert(false)
        }, 2000)
      }} key={row.id}> {/* Use row.id instead */}
        {row.getVisibleCells().map(cell => (
          <td key={cell.id}> {/* Use cell.id instead */}
            {flexRender(
              cell.column.columnDef.cell,
              cell.getContext()
            )}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
</table>
      <div className='pagination'>
        <button onClick={()=>table.setPageIndex(0)}>First page</button>
        <button disabled={!table.getCanPreviousPage()} onClick={()=>table.previousPage()}>Prev page</button>
        <button disabled={!table.getCanNextPage()} onClick={()=>table.nextPage()}>Next page</button>
        <button onClick={()=>table.setPageIndex(table.getPageCount() - 1)}>Last page</button>
      </div>

    </main>
    </div>
  )
}

export default EachUser
