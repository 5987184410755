import React, { useEffect, useState } from 'react'
import './analytics.css'

const Analytics = ({allUsers}) => {
    const freeUsers = allUsers.filter(item => item.pro === false)
    const proUsersData = allUsers.filter(item => item.pro === true)
    const proUsers = [...proUsersData.slice(0,3), proUsersData[7]]
    const pUsers = allUsers.filter(item => item.walletLimit === 100)
    const vUsers = allUsers.filter(item => item.walletLimit === 200)
    const oUsers = allUsers.filter(item => item.walletLimit === 400)
    const zUsers = allUsers.filter(item => item.walletLimit === 600)
    const inactiveUsers = allUsers.filter(item=> item.wallets.length < 1)
    const inactiveUsersChatID = inactiveUsers.map(item=>{
        return item.chat_id
    })
    console.log(inactiveUsersChatID)

   
    return (
        <div className='analytics'>
            <h1>
                Analytics
            </h1>
            <main>
                <ul>
                    <li><a href='/allusers'><img alt='img' src='/images/blogImg.png'/><h2>All users</h2><span>{allUsers.length}</span></a></li>
                    <li><a href='/prousers'><img alt='img' src='/images/users.png'/><h2>Pro users</h2><span>{proUsers.length}</span></a></li>
                    <li><a href='/freeusers'><img alt='img' src='/images/subs.png'/><h2>Free users</h2><span>{freeUsers.length}</span></a></li>
                    <li><a href='/zeus'><img alt='img' src='/images/visit.png'/><h2>Zeus users</h2><span>{zUsers.length}</span></a></li>
                </ul>
            </main>
            <main style={{marginTop: '2rem'}}>
                <ul>
                    <li><a href='/odin'><img alt='img' src='/images/blogImg.png'/><h2>Odin users</h2><span>{oUsers.length}</span></a></li>
                    <li><a href='/valkyrie'><img alt='img' src='/images/users.png'/><h2>Valkyrie users</h2><span>{0}</span></a></li>
                    <li><a href='/phoenix'><img alt='img' src='/images/subs.png'/><h2>Phoenix users</h2><span>{2}</span></a></li>
                </ul>
            </main>
        </div>
    )
}

export default Analytics