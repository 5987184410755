import React, { useState, useMemo, useEffect } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table';
import * as XLSX from 'xlsx';
import './Allwallets.css'

const Allwallets = ({ users }) => {
  const allWallets = users.flatMap(user => user.wallets);
  const uniqueWallets = Array.from(new Map(allWallets.map(wallet => [wallet.address, wallet])).values());
  console.log('unique', uniqueWallets);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [pageSize] = useState(50); // Page size
  const [pageIndex, setPageIndex] = useState(0); // Track the current page index
  const [alert, setAlert] = useState(false)


  const data = useMemo(() => {
    if (!searchQuery) return uniqueWallets;
    return uniqueWallets.filter(wallet =>
      wallet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      wallet.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      wallet.group.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, uniqueWallets]);

  useEffect(() => {
    // Calculate total page count
    setPageCount(Math.ceil(data.length / pageSize));
  }, [data, pageSize]);

  const columns = useMemo(
    () => [
      {
        header: 'Address',
        accessorKey: 'address',
        footer: 'Address',
      },
      {
        header: 'Name',
        accessorKey: 'name',
        footer: 'Name',
      },
      {
        header: 'Group',
        accessorKey: 'group',
        footer: 'Group',
      },
    ],
    []
  );

  const table = useReactTable({
    data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize), // Only render data for the current page
    columns,
    pageCount, // Manually set page count
    manualPagination: true, // Enable manual pagination
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pageIndex,
    },
    onPaginationChange: (updater) => setPageIndex(updater), // Handle page changes
  });

  const handleDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(uniqueWallets);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'wallets.xlsx');
  };

  return (
    <main className='w3-container' style={{ marginBottom: '9rem' }}>
     {
        alert && <p className='copied'>Copied</p>
    }
      <main
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '4rem',
          marginBottom: '1rem',
        }}
      >
        <p style={{ fontSize: '25px', fontWeight: '900' }}>TOTAL : {uniqueWallets.length}</p>
        <button
          onClick={handleDownload}
          style={{
            padding: '5px 10px',
            backgroundColor: 'rgb(93, 219, 223)',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Download PDF
        </button>
      </main>
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search by address, name, or group"
        style={{ marginBottom: '1rem', padding: '0.5rem', width: '100%', outline: 'none' }}
      />
      <table className='w3-table-all'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ cursor: 'pointer' }}>
          {table.getRowModel().rows.map(row => (
            <tr onClick={()=>{
        navigator.clipboard.writeText(row.original.address)
        setAlert(true)
        setTimeout(()=>{
            setAlert(false)
        }, 2000)
      }} key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
          First page
        </button>
        <button onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex === 0}>
          Prev page
        </button>
        <button onClick={() => setPageIndex(pageIndex + 1)} disabled={pageIndex === pageCount - 1}>
          Next page
        </button>
        <button onClick={() => setPageIndex(pageCount - 1)} disabled={pageIndex === pageCount - 1}>
          Last page
        </button>
      </div>
    </main>
  );
};

export default Allwallets;
