import axios from "axios"
const apiUrl =  process.env.REACT_APP_API_URL

export async function getAllUsers() {
    try{
       const res = await axios.get(apiUrl)
        const data = res.data
        return data
    }catch(e){
        console.log(e)
    }
}

export async function getAllProUsers() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

export async function getAllZeus() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

export async function getAllOdin() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

export async function getAllValk() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

export async function getAllPhn() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

export async function getAllFree() {
    try{
        console.log('nice')
    }catch(e){
        console.log(e)
    }
}

