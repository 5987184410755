import React from 'react'
import Analytics from '../../components/Analytics/Analytics'
import './Main.css'
import Traffic from '../../components/Traffic/Traffic'
import ChartArea from '../../components/chartArea/ChartArea'
import { useOutletContext } from 'react-router-dom'

const Main = ({users}) => {
  console.log('outlet', users)
  return (
    <div className='main'>
    <h1>
      WELCOME
    </h1>
        <div>
            <Analytics allUsers={users}/>
        </div>
       
        <div>
            <Traffic/>
        </div>
    </div>
  )
}

export default Main