// import { useEffect, useState } from 'react';
import Dashboard from './pages/Dashboard/Dashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { useState, useEffect } from 'react';
import { getAllUsers } from './api/api';
import SignUp from './pages/SignUp/SignUp';
import Login from './pages/Login/Login';
import Main from './pages/main/Main';
import Tables from './components/Tables/Tables';
import EachUser from './components/EachUser/EachUser';
import Allwallets from './components/Allwallets/Allwallets';
import Messages from './pages/Messages/Messages';
import Walletuser from './components/WalletbyUser/Walletuser';
import SendLink from './pages/SendLinks/SendLink';

function App() {

  // const [signedIn, setSignedin] = useState()
  // const [loginData, setloginData] = useState()

  // const updateLog = (newValue) => {
  //   setSignedin(newValue)
  // }

  // const data = localStorage.getItem('data')

  // useEffect(() => {
  //   setloginData(JSON.parse(data))
  //   console.log(loginData)
  // }, [data])

  const [allUser, setAllUsers] = useState([])
  useEffect(()=>{
  async function all(params) {
  const dat = await getAllUsers()
  console.log(dat)
  setAllUsers(dat)
  console.log(allUser)
  }
  all()
  },[])

  useEffect(() => {
      console.log("Updated allUser:", allUser);
    }, [allUser]);

  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Dashboard users={allUser} />}>
            <Route path='/' element={<Main users={allUser} />} />
            <Route path='/allusers' element={<Tables users={allUser} type='all' />} />
            <Route path='/freeusers' element={<Tables users={allUser} type='free' />}/>
            <Route path='/prousers' element={<Tables users={allUser} type='pro' />} />
            <Route path='/phoenix' element={<Tables users={allUser} type='phoenix' />} />
            <Route path='/valkyrie' element={<Tables users={allUser} type='val' />} />
            <Route path='/odin' element={<Tables users={allUser} type='odin' />} />
            <Route path='/zeus' element={<Tables users={allUser} type='zeus' />} /> 
            <Route path='/allusers/user' element={<EachUser users={allUser} type='zeus' />} /> 
            <Route path='/allwallets' element={<Allwallets users={allUser}/>} /> 
            <Route path='/send_wallets' element={<Walletuser users={allUser}/>} /> 
            <Route path='/send-message' element={<Messages users={allUser}/>} /> 
            <Route path='/send-links' element={<SendLink users={allUser}/>} /> 
          </Route>
            <Route path='/add' element={<SignUp />} />
          <Route path='/login' element={<Login />} />
        </Routes>
        <Dashboard />
      </>
    </BrowserRouter>
  );
}

export default App;
